<template>
  <!-- Manage UPS Billing Shipping Modal -->
  <div
    class="modal fade modal-xl modal-fullscreen"
    id="ups_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            UPS Billing Shipping ({{ formData.name }})
          </h5>
          <button
            type="button"
            class="btn-close"
            id="fedexGroupclosebutton"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <el-form
            @submit.prevent="submit()"
            :model="formData"
            ref="formRef"
            class="w-100"
          >
            <div class="row g-9">
              <div class="col-md-12 fv-row">
                <div class="mb-6">
                  <label class="required fs-6 fw-bold mb-2"
                    >Account Number</label
                  >
                  <el-form-item
                    prop="shipping_details.account_number"
                    :rules="{
                      required: true,
                      message: 'Account number is required',
                      trigger: 'change',
                    }"
                  >
                    <el-input
                      v-model="formData.shipping_details.account_number"
                      size="large"
                      type="text"
                      placeholder="Account Number"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-12 fv-row">
                <div class="mb-6">
                  <label class="required fs-6 fw-bold mb-2">Postal Code</label>
                  <el-form-item
                    prop="shipping_details.postal_code"
                    :rules="{
                      required: true,
                      message: 'Postal code is required',
                      trigger: 'change',
                    }"
                  >
                    <el-input
                      v-model="formData.shipping_details.postal_code"
                      size="large"
                      type="text"
                      placeholder="Postal Code"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-12 fv-row">
                <div class="mb-6">
                  <label class="required fs-6 fw-bold mb-2">Country Code</label>
                  <el-form-item
                    prop="shipping_details.country"
                    :rules="{
                      required: true,
                      message: 'Country code is required',
                      trigger: 'change',
                    }"
                  >
                    <el-input
                      v-model="formData.shipping_details.country"
                      size="large"
                      type="text"
                      placeholder="Country Code"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="submit(formRef)"
          >
            {{ generalConstants.SAVE }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ generalConstants.CANCEL }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";

export default {
  name: "ups-billing-shipping-modal",
  components: {},
  props: {
    upsDetails: Object,
  },
  setup(props, { emit }) {
    const formData = reactive({
      trading_partner_id: props.upsDetails.trading_partner_id,
      name: props.upsDetails.name,
      column: "ups_third_party_billing",
      shipping_details: props.upsDetails.shipping_details,
    });
    const formRef = ref(null);
    const generalConstants = globalConstant.general;

    const updateProps = (response) => {
      formData.trading_partner_id = response.trading_partner_id;
      formData.name = response.name;
      formData.shipping_details.account_number =
        response.shipping_details.account_number;
      formData.shipping_details.postal_code =
        response.shipping_details.postal_code;
      formData.shipping_details.country = response.shipping_details.country;
    };

    const submit = async (formRef) => {
      formRef.validate((valid) => {
        if (!valid) return false;

        ApiService.post("trading-partner/update-thirdparty-billing", {
          trading_partner_id: formData.trading_partner_id,
          column: formData.column,
          shipping_details: JSON.stringify(formData.shipping_details),
        })
        .then(({ data }) => {
          if (data) {
            notificationFire(data.message, "success");
            document.getElementById("fedexGroupclosebutton").click();
            emit("getTradingPartners");
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
      });
    };

    onMounted(() => {});

    return {
      formData,
      updateProps,
      formRef,
      generalConstants,
      submit,
    };
  },
};
</script>