<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch" class="form-control form-control-solid w-250px me-2" :placeholder="generalConstants.SEARCH" />
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
            <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
            <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
          </el-tooltip>
        </div>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-warning me-2" v-if="permission.isTradingPartnerMappedSkuAllowed" @click="$router.push({ name: 'mapped-partner-SKU' })"><i class="bi bi-diagram-3-fill fs-4"></i>
          {{ tradingPartnerConstants.MAPPED_PARTNER_SKU }}
        </button>
        <button type="button" class="btn btn-info me-2" @click="setAddress()"><i class="bi bi-geo-alt fs-4"></i>
          {{ tradingPartnerConstants.DEFAULT_ADDRESS }}
        </button>
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-trading-partner' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ generalConstants.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable :table-data="tableData" :table-header="tableHeader" :rows-per-page="searchParams.recordsPerPage" :empty-table-text="noDataFound" :loading="loading" :current-page="1" :enable-items-per-page-dropdown="true" :total="total" ref="updateProps" @current-change="handleCurrentChange" @items-per-page-change="handleSizeChange">
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-partner_image="{ row: data }">
          <div class="symbol symbol-50px symbol-circle me-3"><img :src="data.partner_image" class="" alt=""></div>
        </template>
        <template v-slot:cell-platformName="{ row: data }">
          {{ data.platformName }}
        </template>
        <template v-slot:cell-name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-email="{ row: data }">
          <a :href="'mailto:'+data.email" class="text-gray-600 text-hover-primary mb-1">{{ data.email }}</a>
        </template>
        <template v-slot:cell-website="{ row: data }">
          <a :href="data.website" class="text-gray-600 text-hover-primary mb-1" target="_blank">{{ data.website }}</a>
        </template>
        <template v-slot:cell-status="{ row: data }">
          <div v-if="permission.isEditAllowed">
            <el-switch v-model="data.status" @change="changeStatus($event,data.trading_partner_id)" active-color="#13ce66" inactive-color="#ff4949" />
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed || permission.isOrderExcelMappingAllowed || permission.isProductExcelMappingAllowed || permission.isThirdPartyBillingShippingAllowed">
            <a href="javascript:void(0);" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :id="'menuAction' + data.product_id" >Actions
              <span class="svg-icon svg-icon-5 m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                </svg>
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-350px py-4" data-kt-menu="true">
              <div class="menu-item px-3" v-if="permission.isEditAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'edit-trading-partner', params: { id: data.id } })"> <i class="bi bi-pencil-square"></i> &nbsp; {{ generalConstants.EDIT }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isProductExcelMappingAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'import-product-excel-columns-mapping', params: { id: data.trading_partner_id } })"> <i class="bi bi-map"></i> &nbsp; {{ tradingPartnerConstants.PRODUCTMAPPING }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isOrderExcelMappingAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'import-order-excel-columns-mapping', params: { id: data.trading_partner_id } })"> <i class="bi bi-map-fill"></i> &nbsp; {{ tradingPartnerConstants.ORDERMAPPING }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isPartnerSkuPageAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="$router.push({ name: 'mapped-partner-SKU', params: { id: data.trading_partner_id } })"> <i class="bi bi-people-fill"></i> &nbsp; {{ tradingPartnerConstants.MAPPED_PARTNER_SKU }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isThirdPartyBillingShippingAllowed && isUpsThirdparty">
                <a href="javascript:void(0);" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#ups_modal" @click.prevent="manageUpsBilling(data.trading_partner_id, data.name, data.ups_third_party_billing)"> <i class="bi bi-truck"></i> &nbsp; UPS Billing Shipping</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isThirdPartyBillingShippingAllowed && isFedexThirdparty">
                <a href="javascript:void(0);" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#fedex_modal" @click.prevent="manageFedexBilling(data.trading_partner_id, data.name, data.fedex_third_party_billing)"> <i class="bi bi-truck"></i> &nbsp; Fedex Billing Shipping</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isDeleteAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="remove(data.id, tableData.findIndex((i) => i.id === data.id))"> <i class="bi bi-trash text-danger"></i> &nbsp; {{ generalConstants.DELETE }}</a>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
    <el-form @submit.prevent="submitAddress()" :model="formData" ref="addressRef">
      <el-dialog v-model="dialogVisible" :title="tradingPartnerConstants.DEFAULT_ADDRESS" width="50%">
        <div class="row">
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.NAME }}</label>
            <el-form-item prop="name" :rules="{
              required: true,
              message: 'Name field is required',
              trigger: 'blur',
            }">
              <el-input size="large" v-model="formData.name" :placeholder="tradingPartnerConstants.NAME" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label>{{ tradingPartnerConstants.COMPANY }}</label>
            <el-input size="large" v-model="formData.company" :placeholder="tradingPartnerConstants.COMPANY" />
          </div>
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.ADDRESS1 }}</label>
            <el-form-item prop="address1" :rules="{
              required: true,
              message: 'Address1 field is required',
              trigger: 'blur',
            }">
            <el-input type="textarea" size="large" v-model="formData.address1" :placeholder="tradingPartnerConstants.ADDRESS1" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label>{{ tradingPartnerConstants.ADDRESS2 }}</label>
            <el-form-item prop="address2">
            <el-input type="textarea" size="large" v-model="formData.address2" :placeholder="tradingPartnerConstants.ADDRESS2" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.CITY }}</label>
            <el-form-item prop="city" :rules="{
              required: true,
              message: 'City field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.city" :placeholder="tradingPartnerConstants.CITY" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.STATE }}</label>
            <el-form-item prop="state" :rules="{
              required: true,
              message: 'State field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.state" :placeholder="tradingPartnerConstants.STATE" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.STATE_CODE }}</label>
            <el-form-item prop="state_code" :rules="{
              required: true,
              message: 'State code field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.state_code" :placeholder="tradingPartnerConstants.STATE_CODE" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.COUNTRY }}</label>
            <el-form-item prop="country" :rules="{
              required: true,
              message: 'Country field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.country" :placeholder="tradingPartnerConstants.COUNTRY" />
            </el-form-item>
          </div>
          <div class="col-md-6 mb-1">
            <label class="required">{{ tradingPartnerConstants.COUNTRY_CODE }}</label>
            <el-form-item prop="country_code" :rules="{
              required: true,
              message: 'Country code field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.country_code" :placeholder="tradingPartnerConstants.COUNTRY_CODE" />
            </el-form-item>
          </div>
          <div class="col-md-6">
            <label class="required">{{ tradingPartnerConstants.POSTAL_CODE }}</label>
            <el-form-item prop="postal_code" :rules="{
              required: true,
              message: 'Postal Code field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.postal_code" :placeholder="tradingPartnerConstants.POSTAL_CODE" />
            </el-form-item>
          </div>
          <div class="col-md-6">
            <label class="required">{{ tradingPartnerConstants.PHONE }}</label>
            <el-form-item prop="phone" :rules="{
              required: true,
              message: 'Phone field is required',
              trigger: 'blur',
            }">
            <el-input size="large" v-model="formData.phone" :placeholder="tradingPartnerConstants.PHONE" />
            </el-form-item>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <button type="button" class="btn btn-primary me-2" @click="submitAddress(addressRef)">{{generalConstants.UPDATE}}</button>
            <button type="button" class=" btn btn-secondary" @click="dialogVisible = false">{{generalConstants.CANCEL}}</button>
          </span>
        </template>
      </el-dialog>
    </el-form>

    <div v-if="permission.isThirdPartyBillingShippingAllowed">
      <UpsBillingShippingModal
        :upsDetails="upsDetails"
        @getTradingPartners="getTradingPartners"
        ref="updateUpsProps"
      ></UpsBillingShippingModal>

      <FedexBillingShippingModal
        :fedexDetails="fedexDetails"
        @getTradingPartners="getTradingPartners"
        ref="updateFedexProps"
      ></FedexBillingShippingModal>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from "element-plus";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import UpsBillingShippingModal from "@/components/modals/forms/UpsBillingShippingModal.vue";
import FedexBillingShippingModal from "@/components/modals/forms/FedexBillingShippingModal.vue";

export default {
  name: "trading-partners",
  components: {
    Datatable,
    UpsBillingShippingModal,
    FedexBillingShippingModal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const pageSize = ref(10);
    const total = ref(0);
    const tableData = ref([]);
    const initTradingPartners = ref([]);
    const currentpage = ref(1);
    const updateProps = ref(null);
    const updateUpsProps = ref(null);
    const updateFedexProps = ref(null);
    const generalConstants = globalConstant.general;
    const tradingPartnerConstants = globalConstant.tradingPartner;
    const noDataFound = ref(generalConstants.LOADING);
    const loading = ref(false);
    const dialogVisible = ref(false);
    const addressRef = ref(null);
    const isUpsThirdparty = ref(false);
    const isFedexThirdparty = ref(false);
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
      isProductExcelMappingAllowed: false,
      isOrderExcelMappingAllowed: false,
      isPartnerSkuPageAllowed: false,
      isTradingPartnerMappedSkuAllowed: false,
      isThirdPartyBillingShippingAllowed: false
    });
    const upsDetails = reactive({
      trading_partner_id: 0,
      name: '',
      shipping_details: {
        account_number: '',
        postal_code: '',
        country: ''
      }
    });
    const fedexDetails = reactive({
      trading_partner_id: 0,
      name: '',
      shipping_details: {
        account_number: '',
        name: '',
        phone_number: '',
        address1: '',
        city: '',
        state: '',
        country: ''
      }
    });

    // Get shipping method list
    const getShippingMethod = async() => {
      await ApiService.query("get-shipping-method")
      .then(({ data }) => {
        if (data) {
          data.forEach((obj) => {
            if (obj.function == 'UPS' && obj.third_party_billing == 2) {
              isUpsThirdparty.value = true;
            } else if (obj.function == 'Fedex' && obj.third_party_billing == 2) {
              isFedexThirdparty.value = true;
            }
          });
        }
      })
      .catch((error) => {
        isUpsThirdparty.value = false;
        isFedexThirdparty.value = false;
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    const manageUpsBilling = (trading_partner_id, name, ups_details) => {
      upsDetails.trading_partner_id = trading_partner_id;
      upsDetails.name = name;
      let data = (ups_details != null) ? JSON.parse(ups_details) : '';
      upsDetails.shipping_details.account_number = data ? data.account_number : '';
      upsDetails.shipping_details.postal_code = data ? data.postal_code : '';
      upsDetails.shipping_details.country = data ? data.country : '';
      updateUpsProps.value.updateProps(upsDetails);
    }

    const manageFedexBilling = (trading_partner_id, name, fedex_details) => {
      fedexDetails.trading_partner_id = trading_partner_id;
      fedexDetails.name = name;
      let data = (fedex_details != null) ? JSON.parse(fedex_details) : '';
      fedexDetails.shipping_details.account_number = data ? data.account_number : '';
      fedexDetails.shipping_details.name = data ? data.name : '';
      fedexDetails.shipping_details.phone_number = data ? data.phone_number : '';
      fedexDetails.shipping_details.address1 = data ? data.address1 : '';
      fedexDetails.shipping_details.city = data ? data.city : '';
      fedexDetails.shipping_details.state = data ? data.state : '';
      fedexDetails.shipping_details.country = data ? data.country : '';
      updateFedexProps.value.updateProps(fedexDetails);
    }

    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: "Platform Name",
        key: "platformName",
        sortable: true,
        width: "w-150px",
      },
      {
        name: "Partner Logo",
        key: "partner_image",
        sortable: false,
        width: "w-100px",
      },
      {
        name: "Partner Name",
        key: "name",
        sortable: true,
        width: "w-200px",
      },
      {
        name: "Email",
        key: "email",
        width: "w-200px",
        sortable: true,
      },
      {
        name: "Website",
        key: "website",
        width: "w-200px",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        width: "w-100px",
        sortable: true,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    //Get all Trading Partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("trading-partner", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            initTradingPartners.value = [];
            tableData.value = [];
            data.data.data.forEach((obj) => {
              tableData.value.push({
                id: obj.id,
                trading_partner_id: obj.trading_partner_id,
                partner_image : obj.partner_image_path == null ? '/media/avatars/blank.png' : process.env.VUE_APP_API_URL + "../../../partner_image/" + obj.partner_image_path,
                platformName: obj.get_platform.platform_name,
                name: obj.name,
                email: obj.email,
                website: obj.website,
                ups_third_party_billing: obj.ups_third_party_billing,
                fedex_third_party_billing: obj.fedex_third_party_billing,
                status: obj.status == 1 ? true : false,
              });
              initTradingPartners.value.push({
                id: obj.id,
                trading_partner_id: obj.trading_partner_id,
                partner_image : obj.partner_image_path == null ? '/media/avatars/blank.png' : process.env.VUE_APP_API_URL + "../../../partner_image/" + obj.partner_image_path,
                platformName: obj.get_platform.platform_name,
                name: obj.name,
                email: obj.email,
                website: obj.website,
                status: obj.status == 1 ? true : false,
              });
            });
            total.value = data.data.total;
            updateProps.value.updateProps(
              total.value,
              searchParams.recordsPerPage,
              searchParams.pageNub
            );
          } else {
            tableData.value = [];
            updateProps.value.updateProps(
              total.value,
              searchParams.recordsPerPage,
              searchParams.pageNub,
              generalConstants.NO_DATA_FOUND
            );
          }
          setCurrentPageBreadcrumbs(
            formatText(route.name) + " (" + total.value + ")",
            [{ name: formatText(route.name), active: true }]
          );
          loading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          tableData.value = [];
          loading.value = false;
        });
    };

    //Stash change function
    const changeStatus = (status, id) => {
      ApiService.post("trading-partner/statusupdate", {
        status: status,
        id: id,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Delete Trading Partner Record
    const remove = async (storeId, index) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: "warning",
          center: true,
        }
      ).then(() => {
        ApiService.delete("trading-partner/" + storeId)
          .then((response) => {
            let status = response.status;
            let message = response.data.message;
            if (status == 200) {
              notificationFire(message, "success");
              tableData.value.splice(index, 1);
              total.value = total.value > 0 ? total.value - 1 : 0;
              setCurrentPageBreadcrumbs(
                formatText(route.name) + " (" + total.value + ")",
                [{ name: formatText(route.name), active: true }]
              );
            }
            if(tableData.value.length == 0){
              updateProps.value.updateProps(total.value,searchParams.recordsPerPage,searchParams.pageNub,generalConstants.NO_DATA_FOUND);
            }
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
          });
      });
    };

    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    const handleCurrentChange = async(number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      await getTradingPartners();
      MenuComponent.reinitialization();
    };

    //Handle search event
    const handleSearch = () => {
      tableData.value.splice(
        0,
        tableData.value.length,
        ...initTradingPartners.value
      );
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
        if (results.length == 0) {
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
      }
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
			total.value = tableData.value.length;
			setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    }

    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (
          obj[key] &&
          obj[key].length > 0 &&
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = async() => {
      if(searchParams.search.length > 0){
        searchParams.search = "";
				store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        await getTradingPartners();
        MenuComponent.reinitialization();
      }
    };

    const setAddress = () => {

      dialogVisible.value = true;
    };

    const formData = reactive({
      trading_partner_address_id: "",
      trading_partner_id: 0,
      name: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      state_code: "",
      country: "",
      country_code: "",
      phone: "",
    });

    const submitAddress = (addressRef) => {
      addressRef.validate((valid) => {
        if (!valid) return false;
        loading.value = true;
        ApiService.post("trading-partner/address-update", {
          addressData: formData,
        })
          .then(({ data }) => {
            notificationFire(data.message, "success");
            dialogVisible.value = false;
            loading.value = false;
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
          });
      });
    };

    const fetchDefaultAddress = async () => {
      await ApiService.post("trading-partner/get-trading-partner-default-address")
      .then(({ data }) => {
        if(data.data) {
          formData.name = data.data.name;
          formData.company = data.data.company;
          formData.address1 = data.data.address1;
          formData.address2 = data.data.address2;
          formData.city = data.data.city;
          formData.state = data.data.state;
          formData.state_code = data.data.state_code;
          formData.country = data.data.country;
          formData.country_code = data.data.country_code;
          formData.phone = data.data.phone_number;
          formData.postal_code = data.data.postal_code;
        }
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createTradingPartner") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateTradingPartner") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteTradingPartner") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      if (per.includes("productExcelMapping") || role == "Super Admin")
        permission.isProductExcelMappingAllowed = true;
      if (per.includes("orderExcelMapping") || role == "Super Admin")
        permission.isOrderExcelMappingAllowed = true;
      if (per.includes("tradingPartnerSku") || role == "Super Admin")
        permission.isPartnerSkuPageAllowed = true;
      if (per.includes("tradingPartnerMappedSku") || role == "Super Admin")
        permission.isTradingPartnerMappedSkuAllowed = true;
      if (per.includes("thirdPartyBillingShipping") || role == "Super Admin")
        permission.isThirdPartyBillingShippingAllowed = true;
      await getTradingPartners();
      await fetchDefaultAddress();
      await getShippingMethod();
      MenuComponent.reinitialization();
    })

    return {
      tableData,
      tableHeader,
      noDataFound,
      searchParams,
      getTradingPartners,
      total,
      currentpage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      remove,
      resetSearch,
      changeStatus,
      loading,
      updateProps,
      generalConstants,
      tradingPartnerConstants,
      handleSearch,
      permission,
      dialogVisible,
      setAddress,
      submitAddress,
      addressRef,
      formData,
      upsDetails,
      manageUpsBilling,
      updateUpsProps,
      fedexDetails,
      manageFedexBilling,
      updateFedexProps,
      isUpsThirdparty,
      isFedexThirdparty
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>